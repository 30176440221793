import React, { useEffect, useState } from 'react';
import {
  Box, Container, Heading, Text, Button, Stack, Flex, Spinner, Divider, useToast, Table, Tr, Th, Td, Thead, Tbody, TableContainer
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import Cookies from 'js-cookie';
import { colors } from '../../../styles/theme';
import SubNav from '../../../components/sub-nav';
import { CurrencyFormatter } from '../../../utils/common';

const GoalDetailsPage = () => {
  const { goalId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const loadingCount = useSelector((state) => state.loading.count);

  const [goal, setGoal] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});
  const [rules, setRules] = useState([]);

  const isLoading = () => loadingCount > 0;


  const handleCancelGoal = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals/${goalId}/cancel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400) {
        toast({
          title: data.message || "An error occurred",
          status: 'error',
          isClosable: true,
        });
        return;
      }

    

      console.log(data);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        description: "Check your internet connection",
        status: 'error',
        isClosable: true,
      });
    }
  }

  const fetchGoalDetails = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals/${goalId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400) {
        toast({
          title: data.message || "An error occurred",
          status: 'error',
          isClosable: true,
        });
        return;
      }

    

      setGoal(data.data);
      setTransactions(data.data.transactions);
      setAccountInfo(data.data.account);
      setRules(data.data.rules);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        description: "Check your internet connection",
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchGoalDetails();
  }, [goalId]);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
       <SubNav title={'Goals'} />
      <Container maxW="container.lg" py={{ base: 4, md: 6 }}>
        {/* <Heading size={{ base: "md", md: "lg" }} mb={4}>Goal Details</Heading> */}
        
        {isLoading() ? (
          <Flex justifyContent="center" alignItems="center" h="60vh">
            <Spinner size="lg" color="blue.500" />
          </Flex>
        ) : (
          goal && (
            <Stack spacing={6}>
              {/* Goal Info Section */}
              <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
              <Flex direction={{ base: 'column', md: 'row' }} style={{ justifyContent: 'space-between'}} mt={6} spacing={4}>
                <div>
                <Heading size="md" color={colors.primaryColor}>{goal?.name?.toUpperCase()}</Heading>
                <Text fontSize="sm" color="gray.600">Goal ID: {goal?.id}</Text>
                </div>
                

                <Button
                  size="md" // Change from "xs" to "md" or "lg" for a larger size
                  colorScheme="blue"
                  px={6} // Optional: Increase horizontal padding
                  py={4} // Optional: Increase vertical padding
                  fontSize="lg" // Optional: Increase font size
                  onClick={() => {
                    if (navigator.share) {
                      navigator
                        .share({
                          title: "Goal Details",
                          text: "Check out this goal on our platform!",
                          url: `${process.env.REACT_APP_GOAL_PAGE}?slug=${goal.slug}`,
                        })
                        .then(() => console.log("Shared successfully"))
                        .catch((error) => console.error("Error sharing:", error));
                    } else {
                      // Fallback if Web Share API is not supported
                      navigate(`${process.env.REACT_APP_GOAL_PAGE}?slug=${goal.slug}`);
                    }
                  }}
                >
                  Share
                </Button>

                        </Flex>
                <Divider my={4} />
                <Text><strong>Target Amount:</strong> {CurrencyFormatter(goal?.target_amount)}</Text>
                <Text><strong>Current Amount:</strong> {CurrencyFormatter(goal?.current_amount)}</Text>
                <Text><strong>Status:</strong> {goal?.status}</Text>
                <Text><strong>Maturity Date:</strong> {goal?.maturity_date || "N/A"}</Text>
                <Text mt={4}>{goal?.description || "No description available."}</Text>
              </Box>

              {/* Transactions Section */}
              <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
                <Heading size="md" mb={4}>Transactions</Heading>
                <TableContainer>
                  <Table variant="simple">
                    <Thead bg={colors.primaryColor} color="white">
                      <Tr>
                        <Th color="white">Date</Th>
                        <Th color="white">Amount</Th>
                        <Th color="white">Type</Th>
                        <Th color="white">Description</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {transactions?.length > 0 ? (
                        transactions?.map((transaction) => (
                          <Tr key={transaction?.id}>
                            <Td>{transaction?.created_at}</Td>
                            <Td>{CurrencyFormatter(transaction?.amount)}</Td>
                            <Td>{transaction?.type}</Td>
                            <Td>{transaction?.description}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td colSpan="4" textAlign="center">No transactions available</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Account Info Section */}
              <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
                <Heading size="md" mb={4}>Account Information</Heading>
                <Text><strong>Account Reference:</strong> {accountInfo?.account_reference}</Text>
                <Text><strong>Account Holder:</strong> {accountInfo?.account_name}</Text>
                <Text><strong>Account Number:</strong> {accountInfo?.account_number}</Text>
                <Text><strong>Bank:</strong> {accountInfo?.bank_name}</Text>
                <Text><strong>Collection Channel:</strong> {accountInfo?.collection_channel}</Text>
                <Text><strong>Status:</strong> {accountInfo?.status}</Text>
              </Box>

              {/* Rules Section */}
              <Box bg="white" boxShadow="md" borderRadius="md" p={6}>
                <Heading size="md" mb={4}>Rules</Heading>
                {rules.length > 0 ? (
                  rules.map((rule, index) => (
                    <Box key={index} mb={4}>
                      <Text><strong>Rule ID:</strong> {rule.id}</Text>
                      <Text><strong>Disbursement Details:</strong></Text>
                      <TableContainer>
                        <Table variant="simple" size="sm" mt={2}>
                          <Thead>
                            <Tr>
                              <Th>Account Name</Th>
                              <Th>Account Number</Th>
                              <Th>Bank Name</Th>
                              <Th>Percentage</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {rule?.disbursement_details?.map((detail, idx) => (
                              <Tr key={idx}>
                                <Td>{detail?.account_name}</Td>
                                <Td>{detail?.account_number}</Td>
                                <Td>{detail?.bank_name}</Td>
                                <Td>{detail?.percentage}%</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ))
                ) : (
                  <Text>No rules specified for this goal.</Text>
                )}
              </Box>

              {/* Actions Section */}
              <Flex direction={{ base: 'column', md: 'row' }} mt={6} spacing={4}>
              {goal.status == 'active' &&
              <>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => navigate(`/dashboard/goals/${goal.id}/edit`)}
                  mb={{ base: 2, md: 0 }}
                >
                  Edit Goal
                </Button>
          
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={handleCancelGoal}
                >
                  cancel
                </Button>
                </>}
              </Flex>
            </Stack>
          )
        )}
      </Container>
    </Box>
  );
};

export default GoalDetailsPage;

import React, { useEffect, useState } from 'react';
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Table, Tr, Th, Td, Thead, Tbody, Flex, TableContainer, Spinner, Box, Button, Stack, FormControl, Select } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import Cookies from 'js-cookie';

const WebsitePage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [websites, setWebsites] = useState([]);
  const isLoading = () => loadingCount > 0;

  const fetchWebsites = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/websites/get_for_user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400 || !data.status) {
        toast({
          title: data.message || "An error occurred",
          status: 'error',
          isClosable: true,
        });
        return;
      }

      setWebsites(data.data);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        description: "Check your internet connection",
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchWebsites();
  }, []);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
      <SubNav title={'Websites'} />
  
      <Container maxW="container.lg" py={{ base: 4, md: 6 }}>
      <Flex 
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between" 
        alignItems="center"
        mb={6}
      >
      

        <Stack 
          direction={{ base: 'row', md: 'row' }} 
          justifyContent="space-between" 
          width="100%" 
          spacing={{ base: 2, md: 4 }}
        >
         

          {websites.length == 0 && <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(`/dashboard/websites/create`)}
          >
            Create
          </Button> }

          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(`/dashboard/websites/settlements`)}
          >
            Settlements
          </Button>

         
        </Stack>
      </Flex>

        <TableContainer bg="white" boxShadow="md" borderRadius="md" overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead bg="blue.500">
              <Tr>
               
                <Th color="white">Name</Th>
                <Th color="white">Sub-domain</Th>
                <Th color="white">Domain</Th>
                <Th color="white">Active</Th>
                <Th color="white">Markup (Type and Value)</Th>
                <Th color="white"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() ? (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    <Spinner size="lg" color="blue.500" />
                  </Td>
                </Tr>
              ) : (
                websites.map((website) => (
                  <Tr key={website.id}>
              
                    <Td>{website.name}</Td>
                    <Td>
                      <a 
                        href={`https://${website.sub_domain}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {website.sub_domain}
                      </a>
                    </Td>
                    <Td>
                        {website.domain ? (
                            <a 
                            href={`https://${website.domain}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                            >
                            {website.domain}
                            </a>
                        ) : "N/A"}
                        {website.domain && <p>To fully activate your website, <br />
                         make sure to point your CNAME for <br /> 
                         the subdomain to <strong>your.domain.com</strong>.</p>}
                    </Td>
                    <Td>{website.dns_record_id != null? 'ACTIVE' : 'INACTIVE'}</Td>
                    <Td>
                      {Object.entries(website.markup).map(([key, value]) => (
                        <Box key={key} mb={2}>
                          <strong>{key}:</strong> {value.TYPE} - {value.VALUE}
                        </Box>
                      ))}
                    </Td>
                    <Td>
                    <Button
                        colorScheme="blue"
                        size="sm"
                        onClick={() => navigate(`/dashboard/websites/${website.id}/update`)}
                    >
                        Update
                    </Button> 
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default WebsitePage;

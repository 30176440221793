import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Goals from '../pages/dasboard/goals/goals'
import Details from '../pages/dasboard/goals/details'
import Create from '../pages/dasboard/goals/create'
import Update from '../pages/dasboard/goals/update'




function GoalRoutes() {
  return (
    <Routes>
        <Route path='/' element={<Goals />} />
        <Route path='/:goalId/details' element={<Details />} />
        <Route path='/create' element={<Create />} />
        <Route path='//:id/edit' element={<Update />} />


        
    </Routes>
  )
}

export default GoalRoutes
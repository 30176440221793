import React, { useEffect, useState } from 'react';
import { Container, Heading, Stack, Input, FormControl, FormLabel, Button, useToast, Box, Select } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import SubNav from '../../../components/sub-nav';
import { decrement, increment } from '../../../store/reducers/loading';
import { useDispatch } from 'react-redux';

const UpdateGoalPage = () => {
  const { id } = useParams(); // Get the goal ID from the URL
  const dispatch = useDispatch();
  const [goalData, setGoalData] = useState({
    name: '',
    target_amount: '',
    maturity_date: '',
    disbursement_details: [
      {
        account_name: '',
        account_number: '',
        bank_name: '',
        bank_code: '',
        percentage: '',
      }
    ]
  });
  
  const [banks, setBanks] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchGoal = async () => {
    const token = `Bearer ${Cookies.get('Token')}`;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
      }

      const data = await response.json();
      if (response.ok) {
        console.log(data.data);
        setGoalData(data.data); // Set the fetched goal data
        setGoalData({ ...data.data, disbursement_details: data.data.rules[0].disbursement_details });
      } else {
        toast({
          title: 'Error fetching goal',
          description: data.message || 'Something went wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Network Error',
        description: 'Please check your internet connection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleGetBanks = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/get_banks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
      }

      const data = await response.json();
      dispatch(decrement());
      if (response.ok) {
        setBanks(data.data.map(x => ({ value: x.code, label: x.name })));
      } else {
        toast({
          title: data.message,
          status: 'error',
          description: '',
          isClosable: true,
        });
      }
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        status: 'error',
        description: "Check your internet connection",
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData({ ...goalData, [name]: value });
  };

  const handleDisbursementChange = async (index, e) => {
    const { name, value } = e.target;
    const updatedDisbursements = goalData.disbursement_details.map((detail, i) =>
      i === index ? { ...detail, [name]: value } : detail
    );

    if (name === "bank_code") {
      const selectedBank = banks.find(bank => bank.value === value);
      updatedDisbursements[index].bank_name = selectedBank ? selectedBank.label : '';
    }

    if (name === "account_number") {
      if (updatedDisbursements[index].bank_code && value.length === 10) {
        updatedDisbursements[index].account_name = await handleVerifyAccountNumber(updatedDisbursements[index].bank_code, value);
      }
    }

    setGoalData({ ...goalData, disbursement_details: updatedDisbursements });
  };

  const handleVerifyAccountNumber = async (bankCode, accountNumber) => {
    if (!accountNumber || !bankCode) {
      toast.error("Please provide all values");
      return '';
    }

    const token = `Bearer ${Cookies.get('Token')}`;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/transfer/verify_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ bank_code: bankCode, account_number: accountNumber }),
      });

      if (response.status === 401) {
        navigate("/auth/login");
      }

      const data = await response.json();
      if (response.ok) {
        return data.data.account_name;
      } else {
        toast({
          title: data.message,
          status: 'error',
          isClosable: true,
        });
        return '';
      }
    } catch (error) {
      toast({
        title: 'Network Error',
        description: 'Please check your internet connection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalPercentage = goalData.disbursement_details.reduce(
      (sum, detail) => sum + parseFloat(detail.percentage || 0),
      0
    );

    if (totalPercentage !== 100) {
      toast({
        title: 'Validation Error',
        description: 'The total disbursement percentage must equal 100.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const token = `Bearer ${Cookies.get('Token')}`;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(goalData),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Goal Updated',
          description: 'Your goal has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/dashboard/goals');
      } else {
        toast({
          title: 'Error Updating Goal',
          description: data.message || 'Something went wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Network Error',
        description: 'Please check your internet connection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchGoal(); // Fetch goal details on component mount
    handleGetBanks(); // Fetch bank list
  }, []);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
      <SubNav title={'Goals'} />
      <Container maxW="container.md" py={6}>
        <Heading size="lg" mb={6}>Update Goal</Heading>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Goal Name</FormLabel>
              <Input name="name" value={goalData.name} onChange={handleChange} placeholder="Enter goal name" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Target Amount</FormLabel>
              <Input type="number" name="target_amount" value={goalData.target_amount} onChange={handleChange} placeholder="Enter target amount" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Maturity Date</FormLabel>
              <Input type="date" name="maturity_date" value={goalData.maturity_date} onChange={handleChange} />
            </FormControl>

            <Heading size="md" mt={4} mb={2}>Disbursement Details</Heading>
            {goalData.disbursement_details.map((detail, index) => (
              <Box key={index} border="1px solid #ddd" p={4} borderRadius="md" mb={2}>
                <FormControl isRequired>
                  <FormLabel>Account Number</FormLabel>
                  <Input type="number" name="account_number" value={detail.account_number} onChange={(e) => handleDisbursementChange(index, e)} placeholder="Account number" />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Bank</FormLabel>
                  <Select
                    name="bank_code"
                    value={detail.bank_code}
                    onChange={(e) => handleDisbursementChange(index, e)}
                  >
                    <option value="">Select Bank</option>
                    {banks.map((bank) => (
                      <option key={bank.value} value={bank.value}>
                        {bank.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Account Name</FormLabel>
                  <Input name="account_name" value={detail.account_name} placeholder="Account name" />
                </FormControl>

               

                <FormControl isRequired>
                  <FormLabel>Percentage</FormLabel>
                  <Input type="number" name="percentage" value={detail.percentage} onChange={(e) => handleDisbursementChange(index, e)} placeholder="Percentage" />
                </FormControl>
              </Box>
            ))}

            <Button type="submit" colorScheme="blue">Update Goal</Button>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};

export default UpdateGoalPage;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Progress,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { increment, decrement } from '../../../store/reducers/loading';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import SubNav from '../../../components/sub-nav';
import { CurrencyFormatter } from '../../../utils/common';

const Cashback = () => {
  const [targets, setTargets] = useState([]);
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const isLoading = () => loadingCount > 0;

  const fetchTargets = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/achievements/targets`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );

      if (response.status === 401) {
        navigate('/auth/login');
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400 || !data.status) {
        toast({
          title: data.message || 'An error occurred',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      setTargets(data.data);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: 'Network Error',
        description: 'Check your internet connection',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleClaim = async (id) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/achievements/${id}/targets`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400 || !data.status) {
        toast({
          title: data.message || 'Claim failed',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      toast({
        title: 'Reward Claimed',
        description: data.message || 'Claim successful',
        status: 'success',
        isClosable: true,
      });

      // Optionally update the UI after claiming
      fetchTargets();
    } catch (err) {
      dispatch(decrement());
      toast({
        title: 'Network Error',
        description: 'Unable to claim reward. Please try again.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchTargets();
  }, []);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
       <SubNav title={'Cashbacks'} />
      <Container maxW="container.lg" py={6}>
        <Heading as="h2" size="lg" mb={6}>
          Spending Targets
        </Heading>

        {isLoading() ? (
          <Flex justifyContent="center" alignItems="center" height="50vh">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <Stack spacing={6}>
            {targets.map((target) => (
              <Box
                key={target.id}
                p={4}
                bg="white"
                boxShadow="md"
                borderRadius="md"
              >
                <Flex justify="space-between" align="center" mb={4}>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">
                      {target.spending_target.description}
                    </Text>
                    <Text color="gray.600">
                      Time Frame: {target.spending_target.time_frame}
                    </Text>
                    <Text color="gray.600">
                      Reward: {CurrencyFormatter(target.spending_target.reward)}
                    </Text>
                  </Box>
                  {target.claimed ? (
                    <Button
                      colorScheme="green"
                      size="sm"
                      isDisabled
                    >
                      Claimed
                    </Button>
                  ) : target.reached ? (
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleClaim(target.id)}
                    >
                      Claim
                    </Button>
                  ) : (
                    <Button colorScheme="gray" size="sm" isDisabled>
                      Not Reached
                    </Button>
                  )}
                </Flex>

                <Text fontWeight="medium" mb={2}>
                  Progress: {CurrencyFormatter(target.amount)} / {CurrencyFormatter(target.spending_target.target_amount)}
                </Text>
                <Progress
                  value={
                    (parseFloat(target.amount) /
                      parseFloat(target.spending_target.target_amount)) *
                    100
                  }
                  colorScheme={target.reached ? 'green' : 'blue'}
                  size="lg"
                  borderRadius="md"
                />
              </Box>
            ))}
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default Cashback;

import React, { useState, useEffect } from 'react';
import { Container, Heading, Stack, Input, FormControl, FormLabel, Button, useToast, Box, Select, FormErrorMessage } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import SubNav from '../../../components/sub-nav';
import { decrement, increment } from '../../../store/reducers/loading';
import { useDispatch } from 'react-redux';

const UpdateWebsitePage = () => {
  const dispatch = useDispatch();
  const [websiteData, setWebsiteData] = useState({
    name: '',
    sub_domain: '',
    domain: '',
    markup: {
      BANK_TRANSFER: { TYPE: 'FIXED', VALUE: 100 },
      WALLET_TRANSFER: { TYPE: 'FIXED', VALUE: 100 },
      AIRTIME: { TYPE: 'FIXED', VALUE: 100 },
      SME: { TYPE: 'FIXED', VALUE: 100 },
      DATA: { TYPE: 'FIXED', VALUE: 100 },
      CABLE: { TYPE: 'FIXED', VALUE: 100 },
      POWER: { TYPE: 'FIXED', VALUE: 100 },
    },
  });

  const [oldData, setOldData] = useState({
    name: '',
    sub_domain: '',
    domain: '',
    markup: {
      BANK_TRANSFER: { TYPE: 'FIXED', VALUE: 100 },
      WALLET_TRANSFER: { TYPE: 'FIXED', VALUE: 100 },
      AIRTIME: { TYPE: 'FIXED', VALUE: 100 },
      SME: { TYPE: 'FIXED', VALUE: 100 },
      DATA: { TYPE: 'FIXED', VALUE: 100 },
      CABLE: { TYPE: 'FIXED', VALUE: 100 },
      POWER: { TYPE: 'FIXED', VALUE: 100 },
    },
  });

  const [errors, setErrors] = useState({});
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the website ID from the URL

  useEffect(() => {
    // Fetch website data for the logged-in user
    const fetchWebsiteData = async () => {
      const token = `Bearer ${Cookies.get('Token')}`;
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/websites/${id}/get_for_user`, {
          headers: {
            'Authorization': token,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setWebsiteData(data.data); // Pre-populate the form with fetched data
          setOldData(data.data);
        } else {
          toast({
            title: 'Error',
            description: data.message || 'Unable to fetch website details.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Network Error',
          description: 'Please check your internet connection.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchWebsiteData();
  }, [id, toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;

   
  
    if (name.includes('.')) {
      const keys = name.split('.');
      const parentKey = keys[0];
      const childKey = keys[1];
      const nestedKey = keys[2];

      console.log({
        ...websiteData,
        [parentKey]: {
          ...websiteData[parentKey],
          [childKey]: {
            ...websiteData[parentKey][childKey],
            [nestedKey]: value,
          },
        },
      });
  
      setWebsiteData(prevState => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: {
            ...prevState[parentKey][childKey],
            [nestedKey]:  name == "sub_domain"? `${value}.suta.ng` : value,
          },
        },
      }));
    } else {
    
      setWebsiteData({
        ...websiteData,
        [name]:  name == "sub_domain"? `${value}.suta.ng` : value,
      });
    }
  
   
  };

  const validateSubDomain = (subDomain) => {
    // Check if subDomain ends with '.suta.ng' and the part before it is valid
    const regex = /^[a-z0-9-]+$/i;  // Matches valid subdomain characters
    return subDomain.endsWith('.suta.ng') && regex.test(subDomain.split('.').slice(0, -2).join('.'));
  };

  const validateDomain = (domain) => {
    const regex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}$/i;
    return regex.test(domain);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();



    // Validation checks
    let formIsValid = true;
    let newErrors = {};

   

    if (!validateSubDomain(websiteData.sub_domain)) {
      newErrors.sub_domain = 'Sub-domain should only contain letters, numbers, and hyphens.';
      formIsValid = false;
    }

    // Only validate the domain if it is provided
    if (websiteData.domain && !validateDomain(websiteData.domain)) {
      newErrors.domain = 'Please enter a valid domain (e.g., example.com).';
      formIsValid = false;
    }

    setErrors(newErrors);
    if (!formIsValid) return;

    const token = `Bearer ${Cookies.get('Token')}`;
    try {
      dispatch(increment());

    
      const payload = {};
      if (websiteData.name !== oldData.name) payload.name = websiteData.name;
      if (websiteData.sub_domain !== oldData.sub_domain) payload.sub_domain = websiteData.sub_domain;
      if (websiteData.domain !== oldData.domain) payload.domain = websiteData.domain;
      payload.markup = JSON.stringify(websiteData.markup);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/websites/update/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      dispatch(decrement());

      if (response.ok) {
        toast({
          title: 'Website Updated',
          description: 'Your website has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/dashboard/websites');
      } else {
        toast({
          title: 'Error Updating Website',
          description: data.message || 'Something went wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(decrement());
      toast({
        title: 'Network Error',
        description: 'Please check your internet connection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
      <SubNav title={'Update Website'} />
      <Container maxW="container.md" py={6}>
    
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Website Name</FormLabel>
              <Input
                name="name"
                value={websiteData.name}
                onChange={handleChange}
                placeholder="Enter website name"
              />
            </FormControl>
            <FormControl isRequired isInvalid={errors.sub_domain}>
              <FormLabel>Sub-Domain</FormLabel>
              <Stack direction="row" align="center">
                <Input
                  name="sub_domain"
                  value={websiteData.sub_domain.split('.')[0]}
                  onChange={handleChange}
                  placeholder="Enter sub-domain (e.g., example)"
                />
                <Box as="span" fontWeight="bold">.suta.ng</Box>
              </Stack>
              <FormErrorMessage>{errors.sub_domain}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.domain}>
              <FormLabel>Domain (Optional)</FormLabel>
              <Input
                name="domain"
                value={websiteData.domain || ''}
                onChange={handleChange}
                placeholder="Enter full domain (e.g., www.example.com)"
              />
              <FormErrorMessage>{errors.domain}</FormErrorMessage>
            </FormControl>

            <Heading size="md" mt={4} mb={2}>Markup Configuration</Heading>
            {Object.keys(websiteData.markup).map((service) => (
              <Box key={service} border="1px solid #ddd" p={4} borderRadius="md" mb={2}>
                <Heading size="sm" mb={2}>{service}</Heading>

                <FormControl isRequired>
                  <FormLabel>Type</FormLabel>
                  <Select
                    value={websiteData.markup[service].TYPE}
                    onChange={(e) => handleChange({ target: { name: `markup.${service}.TYPE`, value: e.target.value } })}
                  >
                    <option value="FIXED">FIXED</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                  </Select>
                </FormControl>

                <FormControl isRequired mt={2}>
                  <FormLabel>Value</FormLabel>
                  <Input
                    type="number"
                    value={websiteData.markup[service].VALUE}
                    onChange={(e) => handleChange({ target: { name: `markup.${service}.VALUE`, value: e.target.value } })}
                    placeholder="Enter value"
                  />
                </FormControl>
              </Box>
            ))}

            <Button mt={4} colorScheme="teal" type="submit">Update Website</Button>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};

export default UpdateWebsitePage;

import React, { useEffect, useState } from 'react';
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Table, Tr, Th, Td, Thead, Tbody, Flex, TableContainer, Spinner, FormControl, Box } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie';
import Pagination from '../../../components/pagination';
import TaskNav from '../../../components/tasknav';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { colors } from '../../../styles/theme';
import { CurrencyFormatter } from '../../../utils/common';

const GoalsPage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [goals, setGoals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [goalStatus, setGoalStatus] = useState("ALL");

  const isLoading = () => loadingCount > 0;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchGoals(newPage);
  };

  const fetchGoals = async (pageNumber) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals?page=${pageNumber}&status=${goalStatus}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400) {
        toast({
          title: data.message || "An error occurred",
          status: 'error',
          isClosable: true,
        });
        return;
      }

      setGoals(data.data.data);
      setLastPage(data.data.last_page);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        description: "Check your internet connection",
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchGoals(currentPage);
  }, [currentPage, goalStatus]);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
      <SubNav title={'Goals'} />
  

      <Container maxW="container.lg" py={{ base: 4, md: 6 }}>
      <Flex 
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between" 
        alignItems="center"
        mb={6}
      >
        <Heading size={{ base: "md", md: "lg" }} mb={{ base: 4, md: 0 }}>
          Goals Overview
        </Heading>

        <Stack 
          direction={{ base: 'row', md: 'row' }} 
          justifyContent="space-between" 
          width="100%" 
          spacing={{ base: 2, md: 4 }}
        >
          <FormControl width={{ base: "60%", md: "auto" }}>
            <Select
              placeholder="Filter by Status"
              value={goalStatus}
              onChange={(e) => setGoalStatus(e.target.value)}
              bg="white"
              size="sm"
            >
              <option value="ALL">ALL</option>
              <option value="active">ACTIVE</option>
              <option value="completed">COMPLETED</option>
              <option value="canceled">CANCELED</option>
            </Select>
          </FormControl>

          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => navigate(`/dashboard/goals/create`)}
          >
            Create
          </Button>
        </Stack>
      </Flex>


        <TableContainer bg="white" boxShadow="md" borderRadius="md" overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead bg={`${colors.primaryColor}`} color="white">
              <Tr>
                <Th color="white">Goal ID</Th>
                <Th color="white">Name</Th>
                <Th color="white">Target Amount</Th>
                <Th color="white">Current Amount</Th>
                <Th color="white">Status</Th>
                <Th color="white">Maturity Date</Th>
                <Th color="white">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() ? (
                <Tr>
                  <Td colSpan="7" textAlign="center">
                    <Spinner size="lg" color="blue.500" />
                  </Td>
                </Tr>
              ) : (
                goals.map((goal) => (
                  <Tr key={goal.id}>
                    <Td>{goal.id}</Td>
                    <Td>{goal.name}</Td>
                    <Td>{CurrencyFormatter(goal.target_amount)}</Td>
                    <Td>{CurrencyFormatter(goal.current_amount)}</Td>
                    <Td>{goal.status}</Td>
                    <Td>{goal.maturity_date || "N/A"}</Td>
                    <Td>
                      <Stack direction="row" spacing={2}>
                        <Button
                          size="xs"
                          colorScheme="blue"
                          onClick={() => navigate(`/dashboard/goals/${goal.id}/details`)}
                        >
                          View
                        </Button>
                        <Button
                          size="xs"
                          colorScheme="blue"
                          onClick={() => {
                            if (navigator.share) {
                              navigator
                                .share({
                                  title: "Goal Details",
                                  text: "Check out this goal on our platform!",
                                  url: `${process.env.REACT_APP_GOAL_PAGE}?slug=${goal.slug}`,
                                })
                                .then(() => console.log("Shared successfully"))
                                .catch((error) => console.error("Error sharing:", error));
                            } else {
                              // Fallback if Web Share API is not supported
                              navigate(`${process.env.REACT_APP_GOAL_PAGE}?slug=${goal.slug}`);
                            }
                          }}
                        >
                          Share
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Flex justifyContent="center" mt={8}>
          <Pagination currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />
        </Flex>
      </Container>
    </Box>
  );
};

export default GoalsPage;

const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;

import React, { useEffect, useState } from 'react';
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Table, Tr, Th, Td, Thead, Tbody, Flex, TableContainer, Spinner, Box, Button, Stack, FormControl, Select, Badge } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import Cookies from 'js-cookie';

const SettlementPage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [settlements, setSettlements] = useState([]);
  const isLoading = () => loadingCount > 0;

  const fetchSettlements = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/websites/settlements`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401) {
        navigate("/auth/login");
        return;
      }

      const data = await response.json();
      dispatch(decrement());

      if (response.status >= 400 || !data.status) {
        toast({
          title: data.message || "An error occurred",
          status: 'error',
          isClosable: true,
        });
        return;
      }

      setSettlements(data.data.data); // Adjust for the nested structure in the response
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "Network Error",
        description: "Check your internet connection",
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchSettlements();
  }, []);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
      <SubNav title={'Settlements'} />

      <Container maxW="container.lg" py={{ base: 4, md: 6 }}>
        <Flex 
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between" 
          alignItems="center"
          mb={6}
        >
          <Stack 
            direction={{ base: 'row', md: 'row' }} 
            justifyContent="space-between" 
            width="100%" 
            spacing={{ base: 2, md: 4 }}
          >
            {settlements.length === 0 && <Button
              colorScheme="blue"
              size="sm"
              onClick={() => navigate(`/dashboard/websites/create`)}
            >
              Create
            </Button>}
          </Stack>
        </Flex>

        <TableContainer bg="white" boxShadow="md" borderRadius="md" overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead bg="blue.500">
              <Tr>
                <Th color="white">Website Name</Th>
                
                <Th color="white">Total Amount</Th>
                <Th color="white">Settled Amount</Th>
                <Th color="white">Settlement Date</Th>
                <Th color="white">Transaction Date</Th>
                <Th color="white">Status</Th>
               
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() ? (
                <Tr>
                  <Td colSpan="7" textAlign="center">
                    <Spinner size="lg" color="blue.500" />
                  </Td>
                </Tr>
              ) : (
                settlements.map((settlement) => (
                  <Tr key={settlement.id}>
                    <Td>{settlement?.website?.name}</Td>
                    
                    <Td>{settlement.total_amount}</Td>
                    <Td>{settlement.settled_amount}</Td>
                    <Td>{new Date(settlement.settlement_date).toLocaleString()}</Td>
                    <Td>{new Date(settlement.transaction_date).toLocaleString()}</Td>
                    <Td>
                      <Badge colorScheme={settlement.settled === 1 ? 'green' : 'yellow'}>
                        {settlement.settled === 1 ? 'SETTLED' : 'PENDING'}
                      </Badge>
                    </Td>
                  
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default SettlementPage;

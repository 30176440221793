import React, { useEffect, useState } from 'react';
import { Container, Heading, Stack, Input, FormControl, FormLabel, Button, useToast, Box, Select } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SubNav from '../../../components/sub-nav';
import { decrement, increment } from '../../../store/reducers/loading';
import { useDispatch } from 'react-redux';

const CreateGoalPage = () => {
  const dispatch = useDispatch();
  const [goalData, setGoalData] = useState({
    name: '',
    target_amount: '',
    maturity_date: '',
    disbursement_details: [
      {
        account_name: '',
        account_number: '',
        bank_name: '',
        bank_code: '',
        percentage: '',
      }
    ]
  });

  const [banks, setBanks] = useState([]);
  
  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData({ ...goalData, [name]: value });
  };


  const handleVerifyAccountNumber = async (bankCode, accountNumber, index) => {
 

    if (!accountNumber && !bankCode) {
      return toast.error("please provide all values");
    }

    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/transfer/verify_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          bank_code: bankCode,
          account_number: accountNumber,
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
      
        return data.data.account_name;
        // handleDisbursementChange(index, {target : { name: "account_name" ,value: data.data.account_name }})
      }
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "An error occurred",
        status: 'error',
        description: 'Network Error',
        isClosable: true,
      })
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
      // console.log(err)
    }


  };



  const handleDisbursementChange = async (index, e) => {
  
    const { name, value } = e.target;
  
    const updatedDisbursements = goalData.disbursement_details.map((detail, i) => 
      i === index ? { ...detail, [name]: value } : detail
    );

  
    if (name === "account_number" || name === "bank_code") {
      const currentDisbursement = updatedDisbursements[index];
      
      if (name === "bank_code") {
        const selectedBank = banks.find(bank => bank.value === value);
      
        currentDisbursement.bank_name = selectedBank ? selectedBank.label : ''; 
      }
      
    
      if(currentDisbursement.account_number.length > 10) {
        toast({
          title: 'Account Number Error',
          description: 'Account number must be 10 digits.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (currentDisbursement.bank_code != '' && currentDisbursement.account_number.length == 10) {
          //Make api calls
          currentDisbursement.account_name = await handleVerifyAccountNumber(currentDisbursement.bank_code,currentDisbursement.account_number, index);
          console.log(currentDisbursement);
       
      }
    }

   
    if (name === "percentage") {
      const totalPercentage = updatedDisbursements.reduce(
          (sum, detail) => sum + parseFloat(detail.percentage || 0),
          0
      );
      if (totalPercentage > 100) {
          toast({
              title: 'Percentage Error',
              description: 'The total percentage must not exceed 100.',
              status: 'error',
              duration: 3000,
              isClosable: true,
          });
          return;
      }
    }
   
   
    setGoalData({ ...goalData, disbursement_details: updatedDisbursements });
};


  const handleGetBanks = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/get_banks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status === 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status === 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          });
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        });
        return;
      }
      setBanks(data.data.map(x => ({ value: x.code, label: x.name })));


    } catch (err) {
      dispatch(decrement());
      toast({
          title: "Network Error",
          status: 'error',
          description: "Check your internet connection",
          isClosable: true,
      });
    }
  }

  const handleAddDisbursement = () => {
    setGoalData({
      ...goalData,
      disbursement_details: [
        ...goalData.disbursement_details,
        {
          account_name: '',
          account_number: '',
          bank_name: '',
          bank_code: '',
          percentage: '',
        }
      ]
    });
  };

  const handleRemoveDisbursement = (index) => {
    setGoalData({
      ...goalData,
      disbursement_details: goalData.disbursement_details.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalPercentage = goalData.disbursement_details.reduce(
      (sum, detail) => sum + parseFloat(detail.percentage || 0), 
      0
    );
    if (totalPercentage !== 100) {
      toast({
        title: 'Validation Error',
        description: 'The total disbursement percentage must equal 100.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const token = `Bearer ${Cookies.get('Token')}`;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/goals`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(goalData),
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Goal Created',
          description: 'Your goal has been successfully created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/dashboard/goals');
      } else {
        toast({
          title: 'Error Creating Goal',
          description: data.message || 'Something went wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Network Error',
        description: 'Please check your internet connection.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    handleGetBanks();
  }, []);

  return (
    <Box bg="#F9FAFC" minHeight="100vh">
       <SubNav title={'Goals'} />
      <Container maxW="container.md" py={6}>
        <Heading size="lg" mb={6}>Create Goal</Heading>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Goal Name</FormLabel>
              <Input name="name" value={goalData.name} onChange={handleChange} placeholder="Enter goal name" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Target Amount</FormLabel>
              <Input type="number" name="target_amount" value={goalData.target_amount} onChange={handleChange} placeholder="Enter target amount" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Maturity Date</FormLabel>
              <Input type="date" name="maturity_date" value={goalData.maturity_date} onChange={handleChange} />
            </FormControl>

            <Heading size="md" mt={4} mb={2}>Disbursement Details</Heading>
            {goalData.disbursement_details.map((detail, index) => (
              <Box key={index} border="1px solid #ddd" p={4} borderRadius="md" mb={2}>
                
                <FormControl isRequired>
                  <FormLabel>Account Number</FormLabel>
                  <Input type="number" name="account_number" value={detail.account_number} onChange={(e) => handleDisbursementChange(index, e)} placeholder="Account number" />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Bank</FormLabel>
                  <Select
                    name="bank_code"
                    value={detail.bank_code}
                    onChange={(e) => handleDisbursementChange(index, e)}
                    placeholder="Select bank"
                  >
                    {banks.map((bank) => (
                      <option key={bank.id} value={bank.value}>
                        {bank.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Account Name</FormLabel>
                  <Input name="account_name" value={detail.account_name} placeholder="Account name" />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Percentage</FormLabel>
                  <Input type="number" name="percentage" value={detail.percentage} onChange={(e) => handleDisbursementChange(index, e)} placeholder="Disbursement percentage" />
                </FormControl>
             
                {goalData.disbursement_details.length > 1 && (
                  <Button colorScheme="red" mt={2} onClick={() => handleRemoveDisbursement(index)}>
                    Remove Disbursement
                  </Button>
                )}
              </Box>
            ))}

            <Button onClick={handleAddDisbursement} colorScheme="blue" variant="outline">Add Disbursement</Button>
            <Button mt={4} colorScheme="teal" type="submit">Create Goal</Button>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};

export default CreateGoalPage;
